<template>
  <div id="payment-header">
    <div class="container-1200 d-flex align-items-center justify-content-between text-align-center">
      <img src="@/assets/img/logos/ideaBuddy-color.svg" alt="">

      <subscription-alert
        v-if="!paymentLoading && subscriptionAlertText"
        class="d-none d-md-block"
        :text="subscriptionAlertText"
      />

      <p v-if="isFreeTrial" class="d-none d-md-block current-plan">
        {{ $t('pages.pricing.yourCurrentPlanIs') }} <span class="payment-plan">Free Trial</span>
      </p>

      <p v-if="!isFreeTrial" class="d-none d-md-block current-plan">
        {{ $t('pages.pricing.yourCurrentPlanIs') }}
        <span class="payment-plan">
          {{ subscriptionPlanName }} / {{ currentUserSubscription.ideas }} {{ Number(currentUserSubscription.ideas) > 1 ? 'ideas' : 'idea' }} / {{ paymentTypes[currentUserSubscription.type] }}
        </span>
      </p>

      <a class="back" @click="onClickBackBtn">
        {{ $t('pages.pricing.backToIdeaBuddy') }}
      </a>
    </div>

    <subscription-alert
      v-if="!paymentLoading && subscriptionAlertText"
      class="d-md-none text-center"
      :text="subscriptionAlertText"
    />

    <p v-if="isFreeTrial" class="d-md-none current-plan text-center">
      {{ $t('pages.pricing.yourCurrentPlanIs') }} <span class="payment-plan">Free Trial</span>
    </p>

    <p v-if="!isFreeTrial" class="d-md-none current-plan text-center">
      {{ $t('pages.pricing.yourCurrentPlanIs') }}
      <span class="payment-plan d-block">
        {{ subscriptionPlanName }} / {{ currentUserSubscription.ideas }} {{ Number(currentUserSubscription.ideas) > 1 ? 'ideas' : 'idea' }} / {{ paymentTypes[currentUserSubscription.type] }}
      </span>
    </p>
    <ib-divider block />
  </div>
</template>

<script>
import MixinCurrencies from '@/mixins/currencies'
import SubscriptionAlert from '@/views/Payment/Components/SubscriptionAlert'
import paymentTypes from '@/data/paymentTypes.json'

import { mapGetters } from 'vuex'

export default {
  name: 'Header',

  components: { SubscriptionAlert },

  mixins: [
    MixinCurrencies
  ],

  props: {
    pageComponent: {
      type: String,
      default: 'SignUp'
    },
    prevRoute: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      paymentHistory: [],
      paymentSlug: '',
      paymentType: '',
      paymentIdeas: 0,
      paymentCurrencySymbol: '',
      paymentOriginalPrice: 0,
      paymentCurrency: null,
      paymentNextBillDate: '',
      paymentLoading: true,
      paymentTypes
    }
  },

  computed: {
    ...mapGetters('user', ['isLoggedIn', 'isSubscribed', 'getCurrentUser', 'subscriptionPlanName', 'isFreeTrial']),
    ...mapGetters('ideaCollection', ['getSharedIdeasCount']),

    isFreeTrialExpired () {
      return this.$store.state.user.id > 0 && !this.$store.state.user.freeTrial && !this.$store.state.user.subscribed && !this.paymentHistory.length
    },
    isSubscriptionExpired () {
      return this.$store.state.user.id > 0 && !this.$store.state.user.subscribed && this.paymentHistory.length
    },

    subscriptionAlertText () {
      if (this.isFreeTrialExpired) {
        return this.$t('subscriptionAlert.freeTrialExpired')
      }
      if (this.isSubscriptionExpired) {
        return this.$t('subscriptionAlert.subscriptionExpired')
      }

      return null
    },

    currentUserSubscription () {
      return this.getCurrentUser.subscription
    },

    userHasPlan () {
      return !this.$store.state.user.freeTrial && this.$store.state.user.subscribed && this.paymentSlug !== ''
    }
  },

  watch: {
    '$route.name': {
      handler () {
        this.loadPaymentData()
      },
      immediate: true
    }
  },

  created () {
    this.loadPaymentData()
  },

  methods: {
    currentSubscribed () {
      if (this.paymentHistory.length) {
        const payment = this.paymentHistory[0]

        this.paymentSlug = payment.slug
        this.paymentType = paymentTypes[payment.type]
        this.paymentIdeas = payment.ideas
        this.paymentOriginalPrice = payment.total_price
        this.paymentCurrency = payment.currency
      }

      this.currencies.forEach((currency) => {
        if (currency.code === this.$store.state.idea.ideaSettings.currency) {
          this.paymentCurrencySymbol = currency.symbol
        }
      })

      if (this.$store.state.user.subscription.nextBillDate !== undefined && this.$store.state.user.subscription.nextBillDate !== null) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' }
        const today = new Date(this.$store.state.user.subscription.nextBillDate.date)

        this.paymentNextBillDate = today.toLocaleDateString('en-US', options)
      }
    },

    async loadPaymentData () {
      if (!this.isLoggedIn) return
      const response = await this.$http.get('payment')
      this.paymentHistory = response.data.payload
      this.currentSubscribed()
      this.paymentLoading = false
    },

    onClickBackBtn () {
      if (this.isSubscribed || this.getSharedIdeasCount) {
        this.back()

        return
      }

      this.logout()
    },

    back () {
      if (!this.prevRoute.name) {
        this.$router.push({ name: 'home' })

        return
      }

      if (this.prevRoute.name === 'payment') {
        this.$router.go(-2)

        return
      }
      this.$router.go(-1)
    },

    logout () {
      this.$cookies.remove('access_token')
      localStorage.removeItem('persisted_state')
      this.$intercom.shutdown()
      this.$store.dispatch('resetState')
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
